import { Box, Icon, Inline, Text, useTextStyles } from '@autoguru/overdrive';
import * as React from 'react';
import {
	ComponentProps,
	memo,
	MouseEventHandler,
	NamedExoticComponent,
} from 'react';
import * as styles from './UserMenuOption.css';

type IconType = ComponentProps<typeof Icon>['icon'] | any;

interface Props {
	label: string;
	icon: IconType;
	onClick: MouseEventHandler;
}

export const UserMenuOption: NamedExoticComponent<Props> = memo(
	({ label, icon, onClick }) => {
		const colourCls = useTextStyles({ colour: 'dark' });
		return (
			<Box
				is="button"
				className={styles.root}
				onClick={onClick}
				paddingX="6"
				paddingY="4"
				width="full"
				alignItems="center"
				backgroundColour="white"
				display="block"
			>
				<Inline>
					<Icon
						className={colourCls}
						icon={icon as unknown as any}
						size="small"
					/>
					<Text is="span" colour="dark">
						{label}
					</Text>
				</Inline>
			</Box>
		);
	},
);
